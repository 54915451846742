@import "src/themes/daikiri/styles/index.scss";
.divider {
  background-color: #bfbfbf;
  height: 1px;
  border: none;
  position: relative;
  overflow: visible;

  &__or-separator {
    &::after {
      content: "or";
      position: absolute;
      background-color: getvar($colors, "neutral", "50");
      padding: 0 10px;
      top: -10px;
      transform: translateX(-10px);
    }
  }
}
